
two-column-layout {
    // display: grid;
    // grid-template-columns: minmax(150px, 100%) minmax(0, auto);
    // grid-template-rows: 52px auto;
    // grid-template-areas:
    //     "top top"
    //     "left right";
    overflow: hidden;
    display: flex;
    flex-direction: row;

    md-sidenav.md-locked-open {
        max-width: unset;
        width: 100%;
    }

    column-left, column-right {
        display: contents;
    }

    .tcl-column {
        // border: 1px solid black;
    }

    .tcl-column-left {
        // grid-area: left;
        flex: 1;
        min-width: 250px;
    }

    .tcl-column-right {
        // grid-area: right;
        border-left: 1px solid currentColor;
    }

    .controller {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 16px;
        z-index: 61;
    }
    
}