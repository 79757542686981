
// @import "angular-material/angular-material.scss";

$page-border-radius: 0px;
$breakpoint-gt-sm: 960px;
$breakpoint-gt-md: 1280px;
$button-border-radius: 4px;
$divider-color: rgba(0,0,0,0.12);
$search-dropdown-height: 300px;
$search-bar-font-size: 18px;
$swift-ease-in-duration: 0;

@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: $breakpoint-gt-sm) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: $breakpoint-gt-md) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

body {
    overflow: hidden;
}

app {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    // display: grid;
    // grid-template-columns: auto;
    // grid-template-rows: fit-content auto;
    // grid-template-areas:
    //     "toolbar"
    //     "main";

    // > .toolbar {
    //     grid-area: toolbar;
    // }

    // > .toolbar {
    //     grid-area: toolbar;
    // }
}

md-icon {
    text-align: center;
}

md-toolbar md-input-container {
    &.md-input-outline .md-input {
        height: unset;
        padding-top: 0;
        padding-bottom: 0;
    }
    .md-errors-spacer {
        height: 0;
        min-height: 0;
    }
}

md-switch .md-label {
    flex: 1;
}

md-list-item {
    md-switch {
        margin-top: 9px;
        margin-bottom: 9px;    
    }
}

.dense-radio-group {
    display: flex;
    flex-direction: row;

    input[type="radio"] {
        // display: none;
        visibility: hidden;
        max-width: 1px;
        margin: 0;

        + label {
            padding: 0 8px;
            margin: 1px -2px;
            position: relative;
            border-radius: 14px;
            overflow: hidden;
            line-height: 30px;
            opacity: 0.4;
            user-select: none;
            cursor: pointer;
        }

        &:not([disabled]) + label:hover {
            background-color: rgba(138,138,138,0.2);
        }

        &:disabled + label {
            opacity: 0.15;
            // text-decoration: line-through;
            cursor: initial;
            .md-ripple-container {
                opacity: 0.5;
            }
        }

        &:checked {

            + label {
                // text-decoration: underline;
                // background-color: rgba(grey, 0.2);
                opacity: 1;
            }
        }
    }
}

// md-backdrop.md-opaque.ng-enter {
//     opacity: 0.48;
// }

header {
    background-color: lightblue;
}

.virtual-container {
    display: contents;
}

home-component {
    height: 100%;
}

.app-fullpage-dialog {
    min-height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: auto minmax(200px, 500px) auto;
    grid-template-rows: auto min-content auto;
    grid-template-areas:
        "header header header"
        ". main ."
        "footer footer footer";

    > .app-dialog {
        grid-area: main;
    }
}

// navigation
ul.app-navigation-container {
    margin: 0;
    padding: 8px;

    > li {
        display: inline-block;
    }

    &.app-navigation-full {

        > li {
            display: list-item;
            list-style: none;
            text-align: center;
            margin-block-end: 16px;
            margin-block-start: 16px;
            
            font-weight: 500;
            font-size: 30px;
            font-style: inherit;
            font-variant: inherit;
            font-family: inherit;
        }
    }
}

.app-navigation-link {
    text-decoration: none;
    margin-inline-end: 4px;
    display: inline-block;
    opacity: 0.4;
    
    &:hover[href] {
        // text-decoration: underline;
        opacity: 1;
    }

    &:not([disabled]):active {
        // transform: translateY(-1px);
        opacity: 1;
    }

    &.app-navigation-link-active {
        // font-weight: bold;
        // text-decoration: underline;
        opacity: 1;
    }
}

.app-sidenav-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .app-sidenav {
        flex-direction: row;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: initial;
    }

    .app-list-header, .app-list-container, .app-list-footer {
        display: flex;
        flex-direction: row;
    }

    .app-sidenav-btn {
        display: flex;

        .name {
            overflow: hidden;
            display: none;
        }

    }

    .app-page {
        border-radius: 0 0 $page-border-radius $page-border-radius;
        overflow: hidden;
    }

}

.app-sidenav {
    display: flex;
    flex-direction: column;
    padding: 1px;

    .app-list-container {
        flex: 1;
    }

    .app-list-container, .app-list-footer, .app-list-header {
        display: flex;
        font-size: 0;
    }
}

.app-sidenav-btn {
    margin: 2px;
    padding: 2px 8px;
    display: block;
    text-align: left;
    text-transform: initial;
    font-size: 12px;
    line-height: 24px;
    min-width: unset;
    border-radius: $button-border-radius;
    max-width: 300px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    border: 1px solid transparent;
    overflow: hidden;
    
    &.current-page {
        border-color: currentColor;
    }

    .icon, .name {
        display: block;
    }

    .icon {
        font-size: 15px;
        flex: 0;

        &.avatar {
            border-radius: 50%;
            width: 48px;
            height: 48px;
            margin: 0 -8px;
        }
    }

    .name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;

        &::before, &::after {
            content: '';
            width: 24px;
            display: inline-block;
        }
    }
}

.app-page-padding {
    padding: 8px 4px;
    box-sizing: border-box;

    @include for-tablet-landscape-up() {
        padding: 16px 8px;
    }
}

.app-toolbar {
    // display: block;
    // padding: 32px 40px 16px 40px;
    min-height: 36px;
    justify-content: center;
    border-bottom: 1px solid currentColor;
    box-sizing: content-box;

    .md-button {
        line-height: 36px;

        &, &.md-icon-button {
            height: 36px;
            min-height: 36px;
            padding: 0 8px;
            margin: 0;
            min-width: unset;
            border-radius: 0;
        }
        &.md-icon-button {
            width: 36px;
            min-width: 36px;
            // margin: 5px 0;
        }
    }

    .app-input {
        margin-left: 0;
    }

    .md-input {

    }

    h2 {
        height: 24px;
        min-height: 24px;
        line-height: 24px;
        font-size: 16px;
        margin: 6px 8px;
        font-weight: unset;
    }

    md-input-container {
        &.md-input-outline .md-input {
            height: unset;
            padding-top: 0;
            padding-bottom: 0;
        }
        .md-errors-spacer {
            height: 0;
            min-height: 0;
        }
    }
}

// .app-search-bar-container {
//     display: inline-block;
//     font-size: 0;
//     position: relative;
//     height: 42px;
//     width: 400px;
// }

// .app-search-bar {
//     md-autocomplete-wrap {
//         box-shadow: none;
//         border: 1px solid $divider-color;
//         border-radius: 7px;
//     }
// }

// .app-search-bar-input-container {
//     margin: 0;
//     padding: 1px;
//     font-size: $search-bar-font-size;
//     border: 1px solid $divider-color;
//     border-radius: 8px;
//     position: absolute;
//     overflow: hidden;

//     .md-errors-spacer {
//         float: right;
//         min-width: 1px;
//         min-height: 0;
//     }

//     .app-search-bar-input {
//         padding: 1px 1px 1px 8px;
//         border-radius: 4px;
//         border: none;
//         font-size: $search-bar-font-size;
//         width: unset;
//         line-height: 36px;
//         height: 36px;
//     }

//     .app-search-dropdown {
//         height: 0;
//         opacity: 0;
//         transition: 0.15s cubic-bezier(0.25, 0.8, 0.25, 1);
//         transition-property: height, opacity;
//     }

//     &.md-input-focused, &:hover {
//         .app-search-bar-input {
//             padding-bottom: 1px;
//         }

//         .app-search-dropdown {
//             height: $search-dropdown-height;
//             opacity: 1;
//         }
//     }
// }

// .app-search-bar-btn {
//     margin: 0;
//     min-width: unset;
//     font-size: $search-bar-font-size;
// }

.app-search-bar-results {
    // position: absolute;
    // left: 0;
    // right: 0;
    height: $search-dropdown-height;
    // border: 1px solid $divider-color;
    border-radius: 8px;
    font-size: $search-bar-font-size;
}

.app-search-bar-results-item {
    // border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    flex-direction: row;
}

.app-search-bar-results-item-btn {
    flex: 1;
    height: 34px;
    margin: 3px 3px 0 3px;
    border-radius: 6px;
    max-width: unset;
    text-align: left;
}

// pagination

app-paginator {
    // display: contents;
    display: flex;
    flex-direction: column;
}

app-paginator-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.app-paginator-item-container {
    margin: 0;
    list-style: none;
    padding: 1px 0;
}

.app-paginator-item {
    min-height: 20px;
    list-style: none;
    // padding: 0 15px;
    display: flex;
    flex-direction: column;
}

.app-virtual-repeat-container {
    // height: 400px;
    flex: 1;

    .app-virtual-repeat-item {
        height: 40px;
    }

    .app-virtual-repeat-item-contents {
        line-height: 38px;
        padding: 1px 0;

        .app-virtual-repeat-item-cell {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .app-icon-btn {

        }
    }
}

// button default styles

.app-icon {
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 8px;
    margin: 1px;
    text-align: center;
}

md-icon .mdi {
    text-align: center;
    line-height: 24px;
}

.app-icon-btn, .app-icon-btn.md-button {
    min-width: unset;
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 8px;
    margin: 1px;
}

.app-txt-avatar-icon {
    display: block;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 8px;
    margin: 0 10px 0 0;
    text-align: center;
    border-radius: 50%;
    // background-color: grey;

    &:first-letter {
        // color: red;
        font-size: 14px;
    }
}

$btn-2line-hpadding: 16px;
$btn-2line-vpadding: 10px;
$btn-2line-left-icon-size: 32px;
$btn-2line-right-icon-size: 24px;
$btn-icon-gap: 20px;

.app-btn {
    margin: 1px 2px;
    text-align: left;
    line-height: 22px;
    padding-top: $btn-2line-vpadding;
    padding-bottom: $btn-2line-vpadding;
    text-transform: initial;

    h3, p {
        margin: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    h3 {
        line-height: 24px;
        font-size: 18px;
    }

    .app-icon {
        // width: $btn-2line-left-icon-size;
        // height: $btn-2line-left-icon-size;
        // font-size: $btn-2line-left-icon-size;
        // line-height: $btn-2line-left-icon-size;
        margin: 0;
    }

    &.app-btn-2-line {
        
    }
    
    &.app-btn-icon-left {
        padding-left: $btn-2line-left-icon-size + $btn-2line-hpadding + $btn-icon-gap;

        .app-btn-icon-left {
            position: absolute;
            left: $btn-2line-hpadding;
            top: 50%;
            transform: translateY(-50%);

            &.app-icon {
                width: $btn-2line-left-icon-size;
                height: $btn-2line-left-icon-size;
                font-size: $btn-2line-left-icon-size;
                line-height: $btn-2line-left-icon-size;
            }
        }

    }
    
    &.app-btn-icon-right {
        padding-right: $btn-2line-right-icon-size + $btn-2line-hpadding + $btn-icon-gap;

        .app-btn-icon-right {
            position: absolute;
            right: $btn-2line-hpadding;
            top: 50%;
            transform: translateY(-50%);

            &.app-icon {
                width: $btn-2line-right-icon-size;
                height: $btn-2line-right-icon-size;
                font-size: $btn-2line-right-icon-size;
                line-height: $btn-2line-right-icon-size;
            }
        }

    }

}

.md-dense > .app-btn:not(.md-dense-disabled), .md-dense :not(.md-dense-disabled) .app-btn:not(.md-dense-disabled) {
    font-size: 13px;
    padding-top: 4px;
    padding-bottom: 4px;

    h3 {
        font-size: 13px;
        line-height: 20px;
    }

    p {
        font-size: 11px;
        line-height: 16px;
    }
}



.app-page {
    &.sidenav-locked-open {
        .app-page-main {
            // width: 300px;
            // flex: 0;
        }
    }
    .app-page-main {
        width: 300px;
        // flex: 1;
    }
    .app-page-sidenav {
        flex: 1;
        max-width: unset;
    }
}

.md-autocomplete-suggestions-container {
    min-width: 300px !important;
}