
$height: 24px;

md-autocomplete.resource-autocomplete-field {
    background: transparent;
    min-width: unset;
    height: $height;
    line-height: $height;

    md-autocomplete-wrap {
        box-shadow: none;
        height: $height;
    }

    input {
        padding: 0;
        height: $height;
        line-height: $height;
    }
}