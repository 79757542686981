
login {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr minmax(250px, 350px) 1fr;
    grid-template-rows: 1fr min-content 1fr;
    grid-template-areas: 
        ". . ."
        ". main ."
        ". . .";
    
    .dialog-window {
        grid-area: main;
    }

    md-card {
        border-radius: 16px;
        margin: 16px 8px;
    }
}