
paginator {
    // display: contents;
    display: flex;
    flex-direction: column;
}

paginator-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.paginator-item-container {
    margin: 0;
    list-style: none;
    padding: 0;
}

.paginator-item {
    min-height: 20px;
    list-style: none;
    // padding: 0 15px;
    display: flex;
    flex-direction: column;
}

.app-virtual-repeat-container {
    // height: 400px;
    flex: 1;

    .app-virtual-repeat-item {
        height: 40px;
    }

    .app-virtual-repeat-item-contents {
        line-height: 38px;
        padding: 1px 0;

        .app-virtual-repeat-item-cell {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .app-icon-btn {

        }
    }
}
